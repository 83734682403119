/* @import url("https://fonts.googleapis.com/css?family=Roboto:700");
body {
  font-family: "Roboto", sans-serif;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  height: 90vh;
}
@keyframes rotate {
  0% {
    transform: rotateX(-37.5deg) rotateY(45deg);
  }
  50% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
  100% {
    transform: rotateX(-37.5deg) rotateY(405deg);
  }
}
.cube,
.cube * {
  position: absolute;
  width: 151px;
  height: 151px;
}
.sides {
  animation: rotate 3s ease infinite;
  animation-delay: 0.8s;
  transform-style: preserve-3d;
  transform: rotateX(-37.5deg) rotateY(45deg);
}
.cube .sides * {
  box-sizing: border-box;
  background-color: #bef979;
  opacity: 25%;
  border: 7.5px solid black;
}
.cube .sides .top {
  animation: top-animation 3s ease infinite;
  animation-delay: 0ms;
  transform: rotateX(90deg) translateZ(150px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes top-animation {
  0% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    transform: rotateX(90deg) translateZ(150px);
  }
}
.cube .sides .bottom {
  animation: bottom-animation 3s ease infinite;
  animation-delay: 0ms;
  transform: rotateX(-90deg) translateZ(150px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes bottom-animation {
  0% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    transform: rotateX(-90deg) translateZ(150px);
  }
}
.cube .sides .front {
  animation: front-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(0deg) translateZ(150px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes front-animation {
  0% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg) translateZ(150px);
  }
}
.cube .sides .back {
  animation: back-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(-180deg) translateZ(150px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes back-animation {
  0% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    transform: rotateY(-180deg) translateZ(150px);
  }
}
.cube .sides .left {
  animation: left-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(-90deg) translateZ(150px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes left-animation {
  0% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    transform: rotateY(-90deg) translateZ(150px);
  }
}
.cube .sides .right {
  animation: right-animation 3s ease infinite;
  animation-delay: 100ms;
  transform: rotateY(90deg) translateZ(150px);
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}
@keyframes right-animation {
  0% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(150px);
  }
  20% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(75px);
  }
  70% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(75px);
  }
  90% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(150px);
  }
  100% {
    opacity: 1;
    transform: rotateY(90deg) translateZ(150px);
  }
}
.text {
  margin-top: 450px;
  color: rgba(242, 119, 119, 1);
  font-size: 1.5rem;
  width: 100%;
  font-weight: 600;
  text-align: center;
} */
.container_image {
  position: absolute;
  left: 60%;
  top: 60%;

  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.container_image img {
  width: 50%;
  height: 50%;
}
