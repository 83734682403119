:root {
  --background-color: linear-gradient(312.98deg, #ebf3f5 0%, #f7f7f4 100%);
  --table-background: linear-gradient(90deg, #009dde 0%, #234678 100%);
  --first-color: #6dd61e;
  --disable-color: #545c68;
  --main-color: #6dd61e;
  --main-Color-500: #6200ee;
  --background-color: #fff;
  --big-medium-size: 25px;
  --white: #fff;
  --main-black-color: #15171a;
  /* Font size */
  --small4-font-size: 14px;
  --small1-font-size: 15px;
  --normal-font-size: 18px;
  --small-font-size: 11px;
  --big-font-size: 21px;
  --big-medium-size: 25px;
  --bigger-font-size: 30px;

  /* ===== z index ===== */
  --z-fixed: 100;

  /* /===== FOnt weight =====/ */
  --font-weight-normal: 400;
  --font-weight-medium: 600;
  --font-weight-regular: 700;
  --font-weight-bold: 900;

  /* /===== Icon  =====/ */
  --logo-height: 65px;
  --icon-height: 20px;
  --icon-width: 20px;
  --icon-gray: grayscale(100%);
}

::before,
::after {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.end_point {
  bottom: 10px !important;
}
.end_point .leaflet-popup-content-wrapper {
  background-color: white;
  margin-right: 30px;
  font-size: 18px;
  color: black;
  margin-top: -10px;
}

.leaflet-container a.leaflet-popup-close-button {
  margin-right: 30px;
  display: none !important;
}
.one_side {
  width: 18% !important;
}
.another_side {
  width: 80%;
}

body {
  color: rgb(27, 27, 27);
  font-family: "roboto", sans-serif;
  -webkit-font-smoothing: "roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-image: url("./Assets//Image/Background/Group.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-attachment: fixed; */
}
.main_section {
  background-image: url("./Assets/Image/Background/Bg.png");
}
button:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  transition: all 0.1s;
}

/* /=====  Table Css Data =====/ */
/* Table Css Data */

/* _____________________ */

/* -------------------------------------------------- */
/* Table css start */
/* -------------------------------------------------- */

table {
  overflow-x: auto;
  white-space: nowrap;
}
.table1 {
  overflow-x: auto;
}

.data_tablee {
  width: 100%;
  overflow: auto;
  padding-bottom: 0px;
  /* padding-bottom: 20px; */
}

.data_tablee table {
  border-collapse: collapse;
}
.button_contact {
  display: flex;
}

.fixHell2 tr th {
  width: 100%;
  padding: 10px 15px 20px 15px;
  color: #797979;
  letter-spacing: 0.3px;
  opacity: 0.8;
  letter-spacing: 0.02em;
  font-size: var(--small14-font-size);
  line-height: 23px;
  font-weight: var(--font-weight-normal);
  border-bottom: 1px dashed #545c68;
}

.data_tablee .fixHell th {
  position: sticky;
  top: 0;
  z-index: 0;

  color: #545c68;
}

.fixHell tr th {
  padding: 20px 15px 10px 15px;
  color: #797979;
  /* letter-spacing: 0.3px; */
  opacity: 0.8;
  /* letter-spacing: 0.02em; */
  font-size: var(--small14-font-size);
  /* line-height: 23px; */

  font-weight: var(--font-weight-normal);
  border-top: 1px solid #545c68;
  border-bottom: 1px dashed #545c68;
}

.fixHellinfs tr th {
  padding: 20px 15px 10px 15px;
  color: #797979;
  letter-spacing: 0.3px;
  opacity: 0.8;
  letter-spacing: 0.02em;
  font-size: var(--small14-font-size);
  line-height: 23px;
  font-weight: var(--font-weight-normal);

  border-bottom: 1px dashed #545c68;
}

.fixHellinfs tr {
  font-size: 12px;
  height: 20px;
  font-weight: var(--font-weight-regular);
  color: #231f20;
  z-index: 1;
}
.fixHell tr {
  font-size: 12px;
  height: 20px;
  font-weight: var(--font-weight-regular);
  color: #231f20;
  z-index: 1;
}
.fixHellTd tr td {
  padding: 20px 15px 20px 15px;
  font-size: var(--small14-font-size);
  font-weight: var(--font-weight-normal);
  color: var(--main-black-color);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  letter-spacing: 0.02em;
}
.fixHellTd1 tr td {
  font-size: var(--small14-font-size);
  font-weight: var(--font-weight-normal);
  color: var(--main-black-color);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  letter-spacing: 0.02em;
}
.fixHellTdinfra tr td {
  padding: 12px 15px 12px 15px;
  font-size: var(--small14-font-size);
  font-weight: var(--font-weight-normal);
  color: var(--main-black-color);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
  letter-spacing: 0.02em;
}

.inactive_tab {
  color: rgba(21, 23, 26, 0.75);
  letter-spacing: 0.3px;
  opacity: 0.5;
  font-size: var(--small1-font-size);
  line-height: 23px;
  font-weight: var(--font-weight-normal);
}

/* -------------------------------------------------- */
/* CHeckbox css start */
/* -------------------------------------------------- */
INPUT[type="checkbox"] {
  background-color: #ddd;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 24px !important;
  height: 23px;
  cursor: pointer;
  background: white;
  border: 1px solid var(--first-color);
}
INPUT[type="radio"] {
  background-color: #ddd;
  border-radius: 50px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 24px;
  height: 23px;
  cursor: pointer;
  background: white;
  border: 1px solid #000;
}

input[type="checkbox"]:checked:before,
input[type="radio"]:checked:before {
  color: var(--button-background) !important;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  border-radius: 2px;
  background-color: var(--first-color);
}

INPUT[type="checkbox"]:checked {
  background-color: var(--first-color);
  background: var(--first-color)
    url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==")
    6px 6px no-repeat;
}
INPUT[type="radio"]:checked {
  background-color: var(--first-color);
  background: var(--first-color)
    url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==")
    5px 5px no-repeat;
  border: 1px solid var(--first-color);
}

input[type="checkbox"][disabled],
input[type="radio"][disabled] {
  background-color: white;
  background: white url("https://img.icons8.com/office/16/000000/cancel-2.png")
    3px 3px no-repeat;
  border: 1px solid red;
  opacity: 0.7;
}

input[type="checkbox"] + label:before {
  border-radius: 2px;
}

.text_color {
  background: linear-gradient(90deg, #00bb6f 0%, #009458 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  letter-spacing: 0.02em;
  font-weight: 700;
}

.boxshadow {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.score_bg {
  background: linear-gradient(90deg, #00bb6f 0%, #009458 100%);
  line-height: 30px;
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-normal);
}
.score_bg1 {
  background: #ffe500;
  line-height: 30px;
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-normal);
}
.score_bg2 {
  background: linear-gradient(315deg, #e9ba39 0%, #eed547 100%);
  line-height: 30px;
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-normal);
}
.score_bg3 {
  background: linear-gradient(315deg, #f9c679 0%, #ee4a13 100%);
  line-height: 30px;
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-normal);
}
.score_bg4 {
  background: #f11a1a;
  line-height: 30px;
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-normal);
}
.score_bg5 {
  background: linear-gradient(315deg, #63e821 0%, #46dc18 100%);
  line-height: 30px;
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-normal);
}
.bg-hover {
  background: rgba(190, 249, 121, 0.5);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.data-dd:hover {
  background: rgba(190, 249, 121, 0.25);
}

/* Map Css start Here */
.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: rgba(109, 214, 30, 0.5) !important;
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  background-color: rgba(109, 214, 30, 0.9) !important;
}
.marker-cluster {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !;
  -moz-border-radius: 50% !;
  -ms-border-radius: 50% !;
  -o-border-radius: 50% !;
}

.marker-cluster div {
  width: 30px !important;
  height: 30px !important;
  position: relative !important;
  top: 9px !important;
  left: 10px !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !;
  -moz-border-radius: 50% !;
  -ms-border-radius: 50% !;
  -o-border-radius: 50% !;
}

.marker-cluster-small div span {
  font-weight: bold !important;
  color: white !important;
  position: relative !important;
  top: 4px !important;
  left: 10px !important;
  font-size: 16px !important;
}
.marker-cluster-medium div span {
  font-weight: bold !important;
  color: white !important;
  position: relative !important;
  top: 4px !important;
  left: 6px !important;
  font-size: 16px !important;
}
.marker-cluster-large div span {
  font-weight: bold !important;
  color: white !important;
  position: relative !important;
  top: 9px !important;
  left: 6px !important;
  font-size: 16px !important;
}

.marker-cluster-large {
  width: 60px !important;
  height: 60px !important;
}

.marker-cluster-large div {
  width: 40px !important;
  height: 40px !important;
  position: relative !important;
  top: 11px !important;
  left: 10px !important;
}
.tooltip {
  background: rgba(0, 0, 0, 0.7);
  color: white;

  /* padding: 5px;
  border-radius: 5px; */
}
.tooltip-arrow {
  position: absolute;
  top: 100%;
  left: 50%;

  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent;
}
